<template>
  <div class="partner content-box" v-if="publishData['18'] && publishData['18'].length">
    <span class="title">合作企业</span>
    <swiper ref="mySwiper" :key="updateKey" :options="swiperOption">
      <swiper-slide
        class="partner-list"
        v-for="(item, index) in partners"
        :key="index"
      >
        <img
          :src="item2.img"
          v-for="(item2, index2) in item"
          :key="index2"
          style="width: 203px; height: 105px; margin-bottom: 39px"
        />
      </swiper-slide>

      <div class="swiper-pagination" slot="pagination"></div>
      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>
    </swiper>
  </div>
</template>

<script>
export default {
  props: {
    publishData: {
      type: Object,
      default: () => {}
    },
    partners: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      updateKey: +new Date(),
      swiperOption: {
        observer: true,
        slidesPerView: 5,
        spaceBetween: 108,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        // 设置前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        // 设置自动轮播
        autoplay: {
          delay: 4000, // 5秒切换一次
          // 用户操作swiper以后，是否禁止autoplay.默认为true中止
          disableOnInteraction: false
        },
        // 设置轮播可循环
        loop: true
      }
    }
  },
  mounted () {
    this.resetBannerNum()
    window.addEventListener('resize', this.resizePage)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resizePage)
  },
  methods: {
    // 页面大小改变
    resizePage () {
      this.resetBannerNum()
    },
    // 重置轮播图一次性的显示数量
    resetBannerNum () {
      if (window.document.body.clientWidth >= 992) {
        this.swiperOption.slidesPerView = 5
      } else if (window.document.body.clientWidth >= 768) {
        this.swiperOption.slidesPerView = 3
      } else if (window.document.body.clientWidth >= 576) {
        this.swiperOption.slidesPerView = 2
      } else {
        this.swiperOption.slidesPerView = 1
      }
      this.updateKey = +new Date()
    }
  }
}
</script>

<style lang='scss' scoped>
::v-deep .partner .swiper-pagination{
  .swiper-pagination-bullet {
    margin: 0px 5px;
    background: hsl(0, 2%, 71%);
  }
  .swiper-pagination-bullet-active{
    background-color: #a3a3a3;
  }
}

// 小于 768 px (手机端)
@media screen and (max-width: 768px) {
  .partner {
    display: none !important;
  }
}
</style>

<style lang='scss' scoped>
span.title {
  display: block;
  width: 140px;
  font-size: 34px;
  font-weight: 600;
  text-align: center;
  padding: 30px 0px 14px 0px;
  color: #333333;
  border-bottom: 2px solid #00A0AC;
  box-sizing: border-box;
}

.partner {
  padding-bottom: 56px;
  margin-top: 50px;
  .title{
    margin: 0 auto;
    margin-bottom: 45px;
  }
  .partner-list {
    box-sizing: border-box;
    height: 262px;
    .partner-item {
      padding: 10px 15px 0;
      .img {
        padding: 16px 12px;
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
        height: 326px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .swiper-container {
    // height: 262px;
    height: 292px;
    background-color: #fff;
    .swiper-button-prev,
    .swiper-button-next {
      color: #CCCCCC;
    }
    .swiper-button-prev{
      left: 0px;
      transform: translateY(-50%)
    }
    .swiper-button-next{
      right: 0px;
      transform: translateY(-50%)
    }
  }
}
</style>
