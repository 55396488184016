<template>
  <div class="root-ele">
    <div class="culture">
      <div class="header" v-if="publishData['12'] && publishData['12'].length">
        <div class="left-line"></div>
        <div>
          <span class="header-title">企业文化</span>
          <span
            class="header-title-english"
            style="color: #a5a5a5; margin-top: 10px"
            >CORPORATE&nbsp; CULTURE</span
          >
        </div>
        <div class="right-line"></div>
      </div>
      <div class="center">
        <div
          class="center_item"
          v-for="(item, index) in publishData['12']"
          :key="index"
        >
          <div class="img gn-flex-center">
            <img :src="item.img" style="width: 63px; height: 63px" />
          </div>
          <div class="btn">
            {{ item.name }}
          </div>
          <div class="text">{{ item.introduce }}</div>
        </div>

        <div
          class="center_item mobile-center_item"
          v-for="(item, index) in publishData['12']"
          :key="index + new Date().getTime()"
        >
          <div class="img gn-flex-center">
            <img :src="item.img" style="width: 63px; height: 63px" />
          </div>
          <div>
            <div class="btn gn-flex-center">
              {{ item.name }}
            </div>
            <div class="text">{{ item.introduce }}</div>
          </div>
        </div>
      </div>

      <!-- 获得荣誉、发展历程，（pc端）-->
      <div class="footer pc-achievementsAndHistory" v-if="publishData['14'] && publishData['14'].length">
        <div class="left">
          <div class="new_title">
            <span>获得荣誉</span>
            <span @click="goMore('achievements')">查看更多></span>
          </div>
          <div class="img">
            <div
              class="img_box"
              v-for="(item, index) in publishData['14'] && publishData['14'].slice(0, 8)"
              :key="index"
            >
              <img :src="item.img" class="gn-full" />
            </div>
          </div>
        </div>
        <div class="right" v-if="publishData['13'] && publishData['13'].length">
          <div class="new_title">
            <span>发展历程</span>
            <span @click="goMore('course')">查看更多></span>
          </div>
          <div class="img">
            <img
              :src="publishData['13'] && publishData['13'][0].img"
              style="width: 399px; height: 337px; margin-top: 48px;object-fit: none !important;"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- 获得荣誉（手机端）-->
    <div class="culture mobile-achievements-header">
      <div class="header">
        <div class="left-line"></div>
        <div>
          <span class="header-title">获得荣誉</span>
          <span
            class="header-title-english"
            style="color: #a5a5a5; margin-top: 10px"
            >GAIN&nbsp; HONOUR</span
          >
        </div>
        <div class="right-line"></div>
      </div>
    </div>
    <div class="mobile-achievements-swiper">
      <swiper :key="updateKey" :options="swiperOptionAchievements">
        <swiper-slide
          class="achievements-list"
          v-for="(item, index) in achievementImgList"
          :key="index"
        >
          <div class="achievements-item">
            <div class="img">
              <img :src="item.img" />
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </div>
    <!-- 发展历程（手机端）-->
    <div class="culture mobile-developHistory">
      <div class="header">
        <div class="left-line"></div>
        <div>
          <span class="header-title">发展历程</span>
          <span
            class="header-title-english"
            style="color: #a5a5a5; margin-top: 10px"
            >DEVELOPMENT&nbsp; PROCESS</span
          >
        </div>
        <div class="right-line"></div>
      </div>
      <div class="img">
        <img
          :src="publishData['13'] && publishData['13'][0].img"
          style="width: 317px; height: 268px;"
        />
      </div>
      <div class="learn-about gn-full-x gn-flex-center" @click="goPage('course')">
        <div class="btn gn-flex-center">了解更多</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPublishListByType } from '@/api/officialWebsite'

export default {
  props: {
    publishData: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      achievementImgList: [],
      updateKey: +new Date(),
      swiperOptionAchievements: {
        observer: true,
        slidesPerView: 4,
        spaceBetween: 43,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        // 设置前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        // 设置自动轮播
        autoplay: {
          delay: 4000, // 5秒切换一次
          // 用户操作swiper以后，是否禁止autoplay.默认为true中止
          disableOnInteraction: false
        },
        // 设置轮播可循环
        loop: true
      }
    }
  },
  mounted () {
    this.getPublish()
    this.resetBannerNum()
    window.addEventListener('resize', this.resizePage)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resizePage)
  },
  methods: {
    // 获取数据
    getPublish () {
      getPublishListByType({
        publish_type: ['14'] // 12:企业文化  13:发展历程  14:获得荣誉  18:合作企业
      }).then(res => {
        this.achievementImgList = res['14'] || []
      })
    },
    // 页面大小改变
    resizePage () {
      this.resetBannerNum()
    },
    // 重置轮播图一次性的显示数量
    resetBannerNum () {
      if (window.document.body.clientWidth >= 992) {
        this.swiperOptionAchievements.slidesPerView = 4
      } else if (window.document.body.clientWidth >= 768) {
        this.swiperOptionAchievements.slidesPerView = 3
      } else if (window.document.body.clientWidth >= 576) {
        this.swiperOptionAchievements.slidesPerView = 2
      } else {
        this.swiperOptionAchievements.slidesPerView = 1
      }
      this.updateKey = +new Date()
    },
    goMore (type) {
      this.$parent.handleGo(type)
    },
    goPage (type) {
      this.$router.push({
        name: 'about',
        query: {
          type
        }
      })
    }
  }
}
</script>

<style lang='scss' scoped>
// 隐藏手机端 企业文化
.mobile-center_item{
  display: none !important;
}
// 隐藏手机端 获得荣誉
.mobile-achievements-header, .mobile-achievements-swiper{
  display: none !important;
}
// 隐藏手机端 发展历程
.mobile-developHistory{
  display: none !important;
}
</style>

<style lang="scss" scoped>
::v-deep .swiper-button-prev,.swiper-button-next {
  &:focus {
    outline: none;
  }
}
.swiper-container {
  height: 100%;
  img {
    width: 100%;
    height: 100%;
  }
  .swiper-button-prev,
  .swiper-button-next {
    color: white;
    background: rgba(0, 0, 0, 0.5);
    width: 40px;
    height: 75px;
    border-radius: 4px;
  }
}

// 企业文化
.culture {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  // margin: 24px 0;
  margin-top: 70px;
  .header {
    width: 1440px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    div:nth-child(1) {
      width: 531px;
      height: 1px;
      background-color: #a5a5a5;
    }
    div:nth-child(2) {
      display: flex;
      flex-direction: column;
      align-items: center;
      span:nth-child(1) {
        font-size: 30px;
        font-weight: 600;
      }
      span:nth-child(2) {
        font-size: 18px;
      }
    }
    div:nth-child(3) {
      width: 531px;
      height: 1px;
      background-color: #a5a5a5;
    }
  }
  .center {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 82px 0px 113px 0px;
    box-sizing: border-box;
    flex-wrap: wrap;
    .center_item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 292px;
      .img {
        width: 157px;
        height: 157px;
        background-color: #f6f6f6;
        border-radius: 50%;
      }
      .btn {
        line-height: 30px;
        margin: 26px 0px 14px 0px;
        padding: 2px 10px;
        background-color: #00a0ac;
        box-sizing: border-box;
        font-size: 18px;
        color: #fff;
        text-align: center;
        border-radius: 5px;
      }
      .text {
        font-size: 22px;
        color: #333;
      }
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    width: 1440px;
    height: 525px;
    .left {
      padding: 42px 32px 53px 28px;
      margin-right: 34px;
      width: 945px;
      height: 100%;
      background-color: #efefef;
      box-sizing: border-box;

      .new_title {
        padding-bottom: 17px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid #dbdada;
        box-sizing: border-box;
        cursor: pointer;
        span:nth-child(1) {
          font-weight: 600;
          font-size: 26px;
        }
        span:nth-child(2) {
          font-weight: 400;
          font-size: 20px;
        }
      }
      .img {
        height: 430px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-top: 34px;
        overflow: auto;
        .img_box {
          margin-bottom: 47px;
          width: 211px;
          height: 148px;
        }
      }
    }
    .right {
      padding: 42px 32px 53px 28px;
      height: 100%;
      flex: 1;
      background-color: #efefef;
      box-sizing: border-box;
      .new_title {
        padding-bottom: 17px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid #dbdada;
        box-sizing: border-box;
        cursor: pointer;
        span:nth-child(1) {
          font-weight: 600;
          font-size: 26px;
        }
        span:nth-child(2) {
          font-weight: 400;
          font-size: 20px;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
// 手机端适配样式
@import './mediaStyle/culture';
</style>
