<template>
  <div class="mobile-partner">
    <div class="title gn-full-x gn-flex-x-center">
      <span>合作企业</span>
    </div>
    <div class="partner">
      <swiper :key="updateKey" :options="swiperOptionPartner">
        <swiper-slide
          class="partner-list"
          v-for="(item, index) in partners"
          :key="index"
        >
          <div class="partner-item">
            <div class="img">
              <img :src="item.img" />
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </div>
  </div>
</template>

<script>
import { getPublishListByType } from '@/api/officialWebsite'

export default {
  data() {
    return {
      updateKey: +new Date(),
      partners: [],
      swiperOptionPartner: {
        observer: true,
        slidesPerView: 4,
        spaceBetween: 43,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        // 设置前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        // 设置自动轮播
        autoplay: {
          delay: 4000, // 5秒切换一次
          // 用户操作swiper以后，是否禁止autoplay.默认为true中止
          disableOnInteraction: false
        },
        // 设置轮播可循环
        loop: true
      }
    }
  },
  mounted () {
    this.getPublish()
    this.resetBannerNum()
    window.addEventListener('resize', this.resizePage)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resizePage)
  },
  methods: {
    // 获取数据
    getPublish () {
      getPublishListByType({
        publish_type: ['18'] // 12:企业文化  13:发展历程  14:获得荣誉  18:合作企业
      }).then(res => {
        this.partners = res['18'] || []
      })
    },
    // 页面大小改变
    resizePage () {
      this.resetBannerNum()
    },
    // 重置轮播图一次性的显示数量
    resetBannerNum () {
      if (window.document.body.clientWidth >= 992) {  // 电脑
        this.swiperOptionPartner.slidesPerView = 4
      } else if (window.document.body.clientWidth >= 768) {   // 大手机
        this.swiperOptionPartner.slidesPerView = 3 
      } else if (window.document.body.clientWidth >= 576) {   // 小手机
        this.swiperOptionPartner.slidesPerView = 3
      } else {
        this.swiperOptionPartner.slidesPerView = 3  // 最小手机
      }
      this.updateKey = +new Date()
    },
  }
}
</script>

<style lang='scss' scoped>
@import './mediaStyle/mobilePartner';
</style>