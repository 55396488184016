<template>
  <div class="pc-about">
    <div class="about content-box">
      <span class="title">关于我们</span>
      <div class="about_box">
        <div class="abstract">
          <div class="title">
            <span>企业简介</span>
            <div class="icon"></div>
          </div>
          <div class="info">
            <div class="name">
              广州市广农数链信息科技有限公司
            </div>
            <div class="info_text">
              <p>2017年2月成立，实缴注册资本1000万元，是一家从事互联网平台软件开发及其推广应用的国家高新技术企业。</p>
              <p>公司致力于打造大数据支撑、网络化共享、智能化协作的农业智慧供应链应用平台，专注于农业流通领域的数字化建设，是数字农业新基建和乡村振兴战略的推动者。</p>
            </div>
          </div>
          <div class="btn" @click="goMore('abstract')">
            查看更多
          </div>
        </div>
        <div class="img">
          <div class="img1">
            <img src="@/assets/images/home/home-about-img1.png" style="width: 100%; height: 100%" />
          </div>
          <div class="img2">
            <img src="@/assets/images/home/home-about-img3.png" style="width: 100%; height: 100%" />
          </div>
          <div class="img3">
            <img src="@/assets/images/home/home-about-img2.png" style="width: 100%; height: 100%" />
          </div>
          <div class="img4">
            <img src="@/assets/images/home/home-about-img4.png" style="width: 100%; height: 100%" />
          </div>
        </div>
      </div>
    </div>
    <div class="lightspot">
      <div class="left">
        <div class="tip">
          <div class="title">农资行业经验</div>
          <div class="num">
            <span>20</span>
            <span class="plus">+</span>
            <span> 年</span>
          </div>
        </div>
        <div class="tip">
          <div class="title">实缴注册资本</div>
          <div class="num">
            <span>1000</span>
            <span> 万</span>
          </div>
        </div>
        <div class="tip">
          <div class="title">国家著作权</div>
          <div class="num">
            <span>32</span>
            <span> 项</span>
          </div>
        </div>
        <div class="tip">
          <div class="title">国家专利</div>
          <div class="num">
            <span>4</span>
            <span> 项</span>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="right_box">
          <div>宝秤农资</div>
          <div>中国农资厂商联盟平台</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goMore (type) {
      this.$parent.handleGo(type)
    }
  }
}
</script>

<style lang='scss' scoped>
span.title {
  display: block;
  width: 140px;
  font-size: 34px;
  font-weight: 600;
  text-align: center;
  padding: 30px 0px 14px 0px;
  color: #333333;
  border-bottom: 2px solid #00A0AC;
  box-sizing: border-box;
}

.about{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 689px;
  margin-top: 80px;
  background-color: #EFEFEF;
  .about_box{
    margin-top: 63px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 231px;
    box-sizing: border-box;
    flex: 1;
    .abstract{
      width: 23vw;
      height: 100%;
      .title{
        font-size: 42px;
        font-weight: 600;
        letter-spacing:5px;
        font-family: Source Han Sans CN;
        .icon{
          margin-top: 5px;
          width: 219px;
          height: 7px;
          background-color: #00A0AC;
        }
      }
      .info{
        margin-top: 49px;
        .name{
          margin-bottom: 22px;
          font-size: 23px;
          font-weight: 600;
        }
        .info_text{
          font-size: 18px;
          line-height: 30px;
        }
      }
      .btn{
        margin-top: 38px;
        width: 174px;
        height: 48px;
        border: 1px solid #333333;
        font-size: 20px;
        text-align: center;
        line-height: 48px;
        cursor: pointer;
      }
    }
    .img{
      position: relative;
      margin-left: 42px;
      height: 100%;
      flex: 1;
      .img1{
        position: absolute;
        left: 100px;
        top: 10px;
        width: 388px;
        height: 261px;
        z-index: 5;
      }
      .img2{
        position: absolute;
        left: 0;
        top: calc(67px + 261px);
        width: 327px;
        height: 132px;
      }
      .img3{
        position: absolute;
        left: 640px;
        top: 0;
        width: 301px;
        height: 202px;
      }
      .img4{
        position: absolute;
        left: calc(52px + 327px);
        bottom: 0;
        width: 482px;
        height: 373px;
        z-index: 4;
      }
    }
  }
}
.lightspot{
  display: flex;
  justify-content: center;
  width: 100%;
  height: 266px;
  background-color: #23A8AD;
  .left{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 934px;
    height: 100%;
    .tip{
      color: #fff;
      font-size: 18px;

      .title{
        font-size: 18px;
      }
      .num{
        span:nth-child(1){
          font-size: 51px;
          font-family: Source Han Sans CN;
        }
        .plus{
          font-size: 35px;
          vertical-align: top;
          font-family: Source Han Sans CN;
        }
      }
    }
  }
  .right{
    width: 640px;
    height: 100%;
    .right_box{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 482px;
      height: 100%;
      background-color: #1673AD;
      color: #fff;
      div:nth-child(1){
        margin-bottom: 20px;
        font-size: 48px;
        font-weight: 600;
      }
      div:nth-child(2){
        font-size: 32px;
      }
    }
  }
}
</style>

<style lang='scss' scoped>
// 小于 768 px (手机端)
@media screen and (max-width: 768px) {
  .pc-about{
    display: none;
  }
}
</style>
