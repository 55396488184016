import { render, staticRenderFns } from "./pcPartner.vue?vue&type=template&id=bde663fa&scoped=true&"
import script from "./pcPartner.vue?vue&type=script&lang=js&"
export * from "./pcPartner.vue?vue&type=script&lang=js&"
import style0 from "./pcPartner.vue?vue&type=style&index=0&id=bde663fa&lang=scss&scoped=true&"
import style1 from "./pcPartner.vue?vue&type=style&index=1&id=bde663fa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bde663fa",
  null
  
)

export default component.exports